import React from "react"
import { Link } from "react-router-dom"
import Helmet from "react-helmet/es/Helmet"
import PlansTable from "../../components/PlansTable/PlansTable"
import "./Features.css"
import Services from "../../components/Services/Services"
import CallToAction from "../../components/CallToAction/CallToAction"
import httpAuthIcon from "../../images/http-auth-icon.svg"
import imageAuthIcon from "../../images/image-auth-icon.svg"
import ipAddrIcon from "../../images/ip-address-icon.svg"
import mwPermissionsIcon from "../../images/mw-permissions-icon.svg"
import editorIcon from "../../images/editor.svg"
import addExtensionsIcon from "../../images/add-extensions-icon.svg"
import forgetAdsIcon from "../../images/forget-ads-icon.svg"
import selfServiceIcon from "../../images/self-service-icon.svg"
import moveWikiIcon from "../../images/move-wiki-icon.svg"
import ssoIcon from "../../images/sso-icon.svg"
import antiSpamSecurityIcon from "../../images/anti-spam-security-icon.svg"
import typingBubbleIcon from "../../images/typing-bubble-icon.svg"
import techSupportIcon from "../../images/tech-support-icon.svg"
import wikiChatIcon from "../../images/wikichat-icon.png";

const Features = () => {
	return (
		<div className="features-container">
			<Helmet>
				<title>Features | MyWikis | Personalized wiki hosting</title>
			</Helmet>
			<div className="gradient-section heading-primary-light">Features</div>
			<div className="statement-banner features-statement-banner">
				<div className="heading-secondary-light">
					At MyWikis, all wikis have features custom-tailored to you.
				</div>
				<p className="subheading-primary-light">
					Explore what we have to offer.
				</p>
			</div>
			<div className="features-privacy-container">
				<div className="features-privacy-statement">
					<h3 className="features-privacy-heading">
						MyWikis takes <span className="color-primary">privacy</span>{" "}
						seriously.
					</h3>
					<p>
						That's why we provide your wiki with multiple layers of protection
						that no other wiki host comes anywhere close to providing.
					</p>
				</div>

				<div className="features-privacy-body body-text-dark">
					<div className="features-privacy-box">
						<div className="features-privacy-box-img-container">
							<img
								src={httpAuthIcon}
								alt="http-auth-icon"
								className="privacy-img"
							/>
						</div>
						<h3 className="features-privacy-box-title color-quaternary">
							HTTP authentication
						</h3>
						<p>
							All users are first authenticated through HTTP authentication that
							you control.
						</p>
					</div>
					<div className="features-privacy-box">
						<div className="features-privacy-box-img-container">
							<img
								src={imageAuthIcon}
								alt="http-auth-icon"
								className="privacy-img"
							/>
						</div>
						<h3 className="features-privacy-box-title color-quaternary">
							Image authentication
						</h3>
						<p>We utilize MediaWiki's image authentication mechanism. </p>
					</div>
					<div className="features-privacy-box">
						<div className="features-privacy-box-img-container">
							<img
								src={ipAddrIcon}
								alt="http-auth-icon"
								className="privacy-img"
							/>
						</div>
						<h3 className="features-privacy-box-title color-quaternary">
							IP address allowlist
						</h3>
						<p>Restrict access to your corporate intranet.</p>
					</div>
					<div className="features-privacy-box">
						<div className="features-privacy-box-img-container">
							<img
								src={mwPermissionsIcon}
								alt="http-auth-icon"
								className="privacy-img"
							/>
						</div>
						<h3 className="features-privacy-box-title color-quaternary">
							Built-in MediaWiki permissions
						</h3>
						<p>
							We haven't forgotten about MediaWiki's inner level of protection.
							Utilize MediaWiki's extensive set of permissions and fine-tune
							them to your needs.
						</p>
					</div>
				</div>
				<br />
				<p className="body-text-dark">
					When creating your wiki, you’ll be able to choose the privacy level
					you’d like for your wiki. <br />
					<b className="color-primary">Existing customer?</b> You can change
					your privacy level anytime through the Self Service portal.
				</p>
				<br />
				<p>We offer various privacy settings for your wiki.</p>
				<br />
				<Link to="/privacy" className="button-tertiary">
					Explore wiki privacy levels
				</Link>
			</div>
			<div className="features-editor-container">
				<div className="features-editor">
					<div>
						<img src={editorIcon} alt="visual-editor" />
					</div>
					<div className="features-editor-text">
						<h3 className="features-editor-heading">
							<span className="color-quaternary">VisualEditor</span> makes it
							easy for you to edit your wiki.
						</h3>
						<p>
							Instead of wasting time learning complicated wiki syntax, you can
							now edit with ease!
						</p>
						<a
							href="https://www.mediawiki.org/wiki/VisualEditor:Test?veaction=edit"
							className="button-quaternary smaller-btn"
						>
							Try it out!
						</a>
					</div> 
				</div>
			</div>
			<div className="feature-box-right feature-6">
					<div className="feature-box-img-container"> 
						<img className="feature-box-img" src={wikiChatIcon} alt="wikichat-icon" style={{ maxWidth: '315px', height: 'auto' }} /> 
					</div>
					<div className="feature-box-text" style={{ color: 'white' }}> 
						<h2>
							Seamless communication. <br /> Elevate collaboration with WikiChat
						</h2>
						<p>
							WikiChat transforms how your team interacts with your wiki by acting as a powerful tool
							for summarizing and retrieving information. Say goodbye to time-consuming searches across
							various pages. WikiChat keeps knowledge accessible and easy to understand, ensuring your
							team can quickly find the answers they need.
						</p> 
					</div>
				</div> 
			<div className="features">
				<div className="feature-box-left feature-1">
					<div className="feature-box-text">
						<h2>Adding Extensions is super extensible and easy.</h2>
						<p>
							MediaWiki has a diverse and strong library of extensions. If the
							wiki isn't doing enough, chances are there's an extension to
							improve your wiki experience!
						</p>
						<p>
							You can install many extensions automatically through our Self
							Service portal. Or, if you aren't sure what you want, let us know
							what you need for your wiki and we'll make recommendations and
							install extensions for you. You can always request specific
							extensions if you know what you want to install.
						</p>
						<Link
							to="/extensions"
							className="button-quaternary smaller-btn"
						>
							See our extensions library
						</Link>
					</div>
					<div className="feature-box-img-container">
						<img className="feature-box-img" src={addExtensionsIcon} alt="add-extensions-icon" />
					</div>
				</div>
				<div className="feature-box-right feature-2">
					<div className="feature-box-img-container">
						<img className="feature-box-img" src={forgetAdsIcon} alt="forget-ads-icon" />
					</div>
					<div className="feature-box-text">
						<h2>
							Forget ads. <br /> Keep your wiki clear of clutter
						</h2>
						<p>
							At MyWikis, we don't believe in filling your screen with junk.
							Instead, we believe in keeping your wiki experience as pure as
							possible. Only the good stuff.
						</p>
					</div>
				</div>
				<div className="feature-box-left feature-3">
					<div className="feature-box-text">
						<h2>
							Easily and quickly manage your wiki settings with MyWikis Self Service.
						</h2>
						<p>
							Our purpose-built MyWikis Self Service lets you do many actions
							automatically. The ability to install extensions and skins, reset
							passwords, and much more, are all at your <b>fingertips</b>.
						</p>
					</div>
					<div className="feature-box-img-container">
						<img className="feature-box-img" src={selfServiceIcon} alt="self-service-icon" />
					</div>
				</div>
				<div className="feature-box-right feature-4">
					<div className="feature-box-img-container">
						<img className="feature-box-img" src={moveWikiIcon} alt="move-wiki-icon" />
					</div>
					<div className="feature-box-text">
						<h2>Need help moving in? We got your back.</h2>
						<p>
							Coming from somewhere else? No worries. MyWikis makes importing
							your wiki a breeze. <br />
							<br />
							Provide us your content, then we'll take care of the rest. Our
							imports are done quickly and with great attention to your existing
							configuration. You’ll have our support throughout the entire
							process.
						</p>
					</div>
				</div>
				<div className="feature-box-left feature-5">
					<div className="feature-box-text">
						<h2>Bring single sign on to your wiki.</h2>
						<p>
							Don't worry about having to maintain yet another database of
							accounts. MyWikis supports all major SSO protocols, including
							SAML, LDAP, OAuth, OpenID, and Shibboleth. <br />
							<br />
							Use another SSO technology? We are able to develop custom SSO
							integrations for your wiki, and have done so in the past.
						</p>
						<Link to="/contact" className="button-quaternary smaller-btn">
							Chat with Sales Team
						</Link>
					</div>
					<div className="feature-box-img-container">
						<img className="feature-box-img" src={ssoIcon} alt="sso-icon" />
					</div>
				</div>
				<div className="feature-box-left feature-1"> 
					<div className="feature-box-img-container">
						<img className="feature-box-img" src={antiSpamSecurityIcon} alt="anti-spam-security-icon" />
					</div>
					<div className="feature-box-text">
						<h2>Cutting edge anti-spam technologies and security.</h2>
						<p>
							MyWikis uses our own, in-house developed technologies and
							strategies to prevent and mitigate spam. We only use the best
							tools available. Your accounts are safer than ever with 2FA
							included.{" "}
							<b>
								We offer two-factor authentication on all wikis to help secure
								your wiki account
							</b>
							.
						</p>
					</div>
				</div>
			</div>
			<div className="features-support">
				<h2 className="heading-secondary-light">
					Professional, prompt, and helpful tech support.
				</h2>
				<div className="support-text">
					<p>
						We're available around the clock, offering you quick but thorough
						assistance with whatever matters you may have.
						<br />
						<br />
						Our support staff are trained to be highly knowledgable and capable
						to help you as quickly yet thoroughly as possible. We don't follow
						flowcharts; we problem solve for each unique situation and
						communicate with you about everything.
					</p>
				</div>
				<Link to="/contact" className="button-primary-light">
					<img
						alt="typing-bubble"
						src={typingBubbleIcon}
						className="typing-bubble-icon"
					/>
					Contact Us
				</Link>
				<img
					className="tech-support-icon"
					src={techSupportIcon}
					alt="tech-sup"
				/>
			</div>
			<div className="plans-container">
				<div className="heading-primary-light">Plans</div>
				<PlansTable />
			</div>
			<Services />
			<CallToAction />
		</div>
	)
}

export default Features
