import React from "react"
import { Link } from "react-router-dom"
import Helmet from "react-helmet/es/Helmet"
import "./WhatIsWikiHosting.css"
import CallToAction from "../../components/CallToAction/CallToAction"

const WhatIsWikiHosting = () => {
	return (
		<div>
			<Helmet>
				<title>
					What is wiki hosting? | MyWikis | Personalized wiki hosting
				</title>
			</Helmet>
			<div className="gradient-section heading-primary-light">
				What is a Wiki? What is Wiki Hosting?
			</div>
			<div className="statement-banner">
				<div className="heading-secondary-light">
					A wiki is a website where multiple users can collaborate to edit
					content and structure. The largest wiki in the world is Wikipedia.{" "}
				</div>
				<p className="body-text-light">
					<i>Did you know that "wiki" is the Hawaiian word for "quick"?</i>
				</p>
			</div>
			<div className="wiki-info">
				<p className="body-text-dark">
					<b>
						A common misconception is that wikis are individual pages. A wiki is
						a collection of wiki pages, which can easily be edited by multiple
						people.
					</b>{" "}
					<br />
					<br />
					Wikipedia is not the only wiki. There are millions of wikis on the
					internet, each with different purposes. Some wikis are used as
					knowledgebases for a hobby, a subject, or an interest. Other wikis
					might be used by companies to share information internally and
					securely. Either way, wikis are used for information collaboration.
					Wikis are very versatile and can be used for many situations. Anybody
					can have their own wiki!
					<hr className="wiki-info-divider" /> To get your own wiki, you can
					either download wiki software and install it by yourself, which is
					time-consuming, difficult, and costly, or use a wiki host. A wiki host
					is a service that sets up and hosts the wiki software for you. All you
					have to do is provide some basic information and soon, you'll have
					your very own wiki!
				</p>
			</div>
			<div className="bullet-section">
				<div className="heading-tertiary bullet-heading">
					While there are many wiki hosts out there, only MyWikis provides you
					with a personalized experience based on your needs and wants.
				</div>
				<ul className="bullet-list body-text-dark">
					<li className="bullet">
						<b>We use the best wiki software there is, MediaWiki.</b> It's the
						same software that Wikipedia uses, so you know it's tried and true.
					</li>
					<li className="bullet">
						<b>We tailor everything on your wiki to your wiki's purpose.</b>{" "}
						Whether you want to create a wiki about your favorite video game or
						your company needs a place to store information, we do the busy work
						for you. Everything on your wiki is configured and fine-tuned just
						for you. Other wiki hosts set up a generic wiki without the settings
						and plugins you need for maximum productivity. At MyWikis, we not only 
						customize your wiki to suit your needs but also provide powerful tools
						like WikiChat to enhance how you access and synthesize information from 
						your wiki's content. We'll make sure you're taken care of here at MyWikis.
					</li> 
					<li className="bullet">
						<b>
							Our staff have over 25 years of MediaWiki experience combined.
						</b>{" "}
						Sit back, relax, and let us handle the technical side—we've got the
						expertise. Gain an unparalleled peace of mind that you are in good
						hands with MyWikis.
					</li>
				</ul>
			</div>
			<div className="help-you">
				<div className="heading-secondary-dark">
					See how MyWikis can help you:
				</div>
				<div className="help-you-boxes">
					<div className="help-you-top-boxes">
						<Link to="/for-personal" className="help-you-top-box top-box-personal heading-secondary-light">MyWikis for Personal Use</Link>
						<Link to="/for-companies" className="help-you-top-box top-box-companies heading-secondary-light">MyWikis for Companies</Link>
					</div>
                    <Link to="/usecases" className="help-you-bottom-box heading-secondary-light">
                        Use Cases
                    </Link>
				</div>
			</div>
            <CallToAction />
		</div>
	)
}

export default WhatIsWikiHosting
