import React from "react"
import { Link } from "react-router-dom"
import Helmet from "react-helmet/es/Helmet"
import { useTranslation } from "react-i18next"
import "./Consulting.css"
import fullSsoIcon from "../../images/full-sso-icon.svg"
import ssoIcon from "../../images/sso-icon.svg"
import cloudMigrationIcon from "../../images/cloud-migration-icon.svg"
import extensionsIcon from "../../images/free-extensions-icon.svg"

import CallToActionConsulting from "../../components/CallToActionConsulting/CallToActionConsulting"
import whyChooseIcon from "../../images/why-choose-icon.png"

import nasaLogo from "../../images/nasa-logo.svg"
import arenanetLogo from "../../images/arenanet-logo.png"
import aysoLogo from "../../images/ayso-logo.png"
import spartancontrolsLogo from "../../images/spartancontrols-logo.svg"

const Consulting = () => {

	const { t } = useTranslation()

	return (
		<div>
			<Helmet>
				<title>Consulting | MyWikis | Personalized wiki hosting</title>
			</Helmet>
			<div className="gradient-section heading-primary-light">
				MyWikis Consulting
			</div>
			<div className="statement-banner">
				<div className="heading-secondary-light">
					Let's make your wikis rock.
				</div>
				<p className="subheading-primary-light">
					Professional MediaWiki consulting by experts with 10+ years of experience.{" "}
				</p>
			</div>
			<div className="partners-container">
				<div className="partners-text">
					{t("consulting.partners.heading")}
				</div>
				<div className="partners-icons-container">
					<div className="partner-icon">
						<img alt="ArenaNet logo" src={arenanetLogo} height="90px" />
					</div>
					<div className="partner-icon">
						<img alt="NASA logo" src={nasaLogo} height="90px" />
					</div>
					<div className="partner-icon">
						<img alt="AYSO logo" src={aysoLogo} height="90px" />
					</div>
					<div className="partner-icon">
						<img alt="Spartan Controls logo" src={spartancontrolsLogo} height="90px" />
					</div>
				</div>
				<hr className="partners-divider" />
			</div>
			<div className="why-choose">
				{/* <img className="why-choose-icon" src={whyChooseIcon} alt="why-choose-mywikis" /> */}
				<div className="heading-primary-dark center-text">
					Why Choose MyWikis?
				</div>
				<div className="why-choose-boxes">
					<div className="why-choose-box empty-box">
						<div className="why-choose-box-number"></div>
						<div className="heading-tertiary"></div>
						<div className="why-choose-box-text body-text-dark"></div>
					</div>
					<div className="why-choose-box">
						<div className="why-choose-box-number">01</div>
						<div className="heading-tertiary">Custom Integrations</div>
						<div className="why-choose-box-text body-text-dark">
							<b>Your wiki is most powerful when it's properly integrated.</b>{" "}
							Don't start all over. We'll integrate it with all of the tools
							your organization uses. Our consultants work with you to fully
							integrate your wiki(s) into your existing IT infrastructure,
							ensuring seamless integrations. Our in-house developers have
							expertise to successfully integrate each need into your wiki.
						</div>
					</div>
					<div className="why-choose-box">
						<div className="why-choose-box-number">02</div>
						<div className="heading-tertiary">Custom Solutions</div>
						<div className="why-choose-box-text body-text-dark">
							MyWikis Consulting provides our expertise in architecting custom
							solutions to solve complex, intricate problems. Our custom
							solutions services include scaling, planning the integration of
							custom features, and the development of custom code to achieve
							your needs.
						</div>
					</div>
					<div className="why-choose-box empty-box">
						<img
							className="why-choose-icon"
							src={whyChooseIcon}
							alt="why-choose-mywikis"
						/>

						<div className="why-choose-box-number"></div>
						<div className="heading-tertiary"></div>
						<div className="why-choose-box-text body-text-dark"></div>
					</div>
					<div className="why-choose-box">
						<div className="why-choose-box-number">03</div>
						<div className="heading-tertiary">Custom Extension Development</div>
						<div className="why-choose-box-text body-text-dark">
							<b>
								Want an extension that doesn't exist yet? We'll make it for you.
							</b>{" "}
							MyWikis develops custom MediaWiki extensions of any complexity.
							From simple additions to MediaWiki to complex, sophisticated
							new features, expert, qualified MyWikis developers have you covered.
							<br />
							<br /> We grant you exclusive rights to use the extension, and
							only share the extension code with you. <br />
							<br /> Are you a MyWikis client? Our clients get access to bundle
							discounts and exclusive rates for custom MediaWiki extension
							development. Not a client?{" "}
							<Link to="/createawiki" className="button-link-blue">
								It's never too late to sign up for a plan today and get started
								in 5 minutes.
							</Link>{" "}
							<br />
							<br /> Featured extensions we've developed:
							<ul>
								<li>
									<a
										className="button-link-blue"
										href="https://github.com/mywikis/NaylorAMS"
									>
										Naylor AMS SSO Integration
									</a>
								</li>
								<li>
									<a
										className="button-link-blue"
										href="https://github.com/mywikis/EmailLogger"
									>
										EmailLogger
									</a>
								</li>
							</ul>{" "}
						</div>
					</div>
					<div className="why-choose-box">
						<div className="why-choose-box-number">04</div>
						<div className="heading-tertiary">Enhanced Wiki Customization</div>
						<div className="why-choose-box-text body-text-dark">
							<b>
								Want a custom skin? Want to seamlessly integrate your wiki into
								your existing website's styles?
							</b>
							<br />
							Look no further. Our enhanced wiki customization services tailor
							to your wiki's use case and heavily modifies MediaWiki to adapt it
							to your brand and your needs. <br />
							<br /> In addition with our extension development services, we can
							also change the layout of your wiki, add additional features to
							the user interface, and help you organize and set up the contents
							of your wiki to be better positioned to achieve critical mass in
							wiki adoption by your organization's members.
						</div>
					</div>
					<div className="why-choose-box">
						<div className="why-choose-box-number">05</div>
						<div className="heading-tertiary">Extended Service Plan</div>
						<div className="why-choose-box-text body-text-dark">
							<b>New to MediaWiki? We'll help you get up to speed.</b>
							<br />
							Are you just getting started with MediaWiki and need additional
							assistance with using your wiki? Learn how to master MediaWiki and
							become a wiki champion for your organization's wiki. <br />
							<ul>
								<li>Step-by-step guidance on using features in MediaWiki</li>
								<li>
									Configuration of wiki settings by editing a page in the wiki
									(i.e. pages in the MediaWiki namespace) or through a special
									page
								</li>
								<li>
									Configuration of wiki scripts and styling (JavaScript and CSS)
								</li>
								<li>Import of templates</li>
								<li>
									Custom skins (to a certain extent—we do not create custom
									designs, but can help implement preexisting small-scale
									designs)
								</li>
								<li>
									…and other tutorial assistance with using the MediaWiki
									software.
								</li>
							</ul>
						</div>
					</div>
					<div className="why-choose-box">
						<div className="why-choose-box-number">06</div>
						<div className="heading-tertiary">
							Companion Mobile App Development
						</div>
						<div className="why-choose-box-text body-text-dark">
							<b>Want a companion mobile app for your wiki?</b>
							<br />
							Our expertise extends beyond web development. <br /> We can create
							a mobile app for your wiki, with your branding and customized just
							for you. <br />
							<br />
							Get your own app for both iOS and Android.
						</div>
					</div>
					<div className="why-choose-box">
						<div className="why-choose-box-number">07</div>
						<div className="heading-tertiary">Cloud First</div>
						<div className="why-choose-box-text body-text-dark">
							We prefer to build natively on the cloud to make your wiki run on
							only the most cutting edge technology, for the most reliability,
							speed, and scaling possible.
							<br />
							<br />
							Staying ahead of the curve means less upgrade work and more
							savings for you in the future.
							<br />
							<br />
							We also work with you to come up with a plan to make sure you're
							never locked into a single cloud service provider.
						</div>
					</div>
					<div className="why-choose-box">
						<div className="why-choose-box-number">08</div>  
						<div className="heading-tertiary">AI-Powered Collaboration</div>
						<div className="why-choose-box-text body-text-dark">
							<b>Bring your team together like never before with WikiChat.</b>{" "}
							Enhance your team's efficiency with WikiChat, an AI-powered chatbot that
							provides instant access to your wiki's information. By synthesizing and retrieving content, 
							WikiChat allows team members to quickly find the information they need without leaving the wiki
							environment. 
						</div>
					</div>
				</div>
			</div>
			<div className="example-services">
				<div className="heading-primary-light">Examples of Services</div>
				<div className="examples-container">
					<div className="example-service">
						<div className="example-service-img-container">
							<img
								className="example-service-img"
								src={fullSsoIcon}
								alt="sso-configuration"
							/>
						</div>
						<div className="heading-tertiary-light">Full SSO Configuration</div>
					</div>
					<div className="example-service">
						<div className="example-service-img-container">
							<img
								className="example-service-img"
								src={cloudMigrationIcon}
								alt="cloud-migration"
							/>
						</div>
						<div className="heading-tertiary-light">Cloud Migration</div>
					</div>
					<div className="example-service">
						<div className="example-service-img-container">
							<img
								className="example-service-img"
								src={ssoIcon}
								alt="sso-configuration"
							/>
						</div>
						<div className="heading-tertiary-light">Automated Backups</div>
					</div>
					<div className="example-service">
						<div className="example-service-img-container">
							<img
								className="example-service-img"
								src={extensionsIcon}
								alt="extensions"
							/>
						</div>
						<div className="heading-tertiary-light">
							Expert Extension Installation
						</div>
					</div>
				</div>
			</div>
			<CallToActionConsulting />
		</div>
	)
}

export default Consulting
